import './style.css';

export default (props) => {

    return (
        <div class="led">  
        {props.status === "on" ? <div id="is-sync"></div> : <div id="is-unsync"></div>}
        </div>
    );
}

