import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { Link } from 'react-router-dom';        
import { useLocation } from 'react-router-dom';
import Led from "../../elements/led/led";

export default (props) => {

   
    const draw = (last_round_onchain, nodes) => {
                
        const res = [];
        
        for(let node of nodes){
            
            const expires_delay = parseInt(node.last_valid) - parseInt(last_round_onchain);
            let expires_msg   = "";
            
            if(expires_delay < 0){
                
                expires_msg = "key is expired" ;
            }
            else if(expires_delay < 10000){
                
                expires_msg = "key expires in "+expires_delay+" blocks" ;
            }
            
            res.push(
                <div id="container">
                    <Led status={node.is_online ? "on" : "off"} />
                    <div id="msg">Node {node.miner} {expires_msg}</div>
                </div>
            );
        }           
        
        return res;
    };
    
    
    return (
        <div id="nodes-sync">        
            {draw(props["last-round-onchain"], props.nodes)}
        </div>
    );
}
