import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import Contracts                                from "../../includes/contracts";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import Slider                                   from "../../elements/slider/slider";
import algosdk                                  from "algosdk";
 
export default (props) => {
    
    const [claim_amount, setClaimAmount]                = useState(0);
    const [user_balance, setUserBalance]                = useState(0);
    const [rewards_as_milkshake, setRewardsAsMilkshake] = useState(false);
  
        
    useEffect(() => {    
               
        loadUserBalance();
       
    }, []);
    
    
    const loadUserBalance = async () => {
        
        const user  = await Api.get("user");
        let balance = 0;
        
        if(user.content && user.content?.address){
            
            const address   = user.content.address;            
            balance         = await Contracts.getUserBalance(address);
            
            setUserBalance(balance);
        }                
    };     


    const formatMilkshakeRewards = (claimed_algos, milkshake_pool) => {
        
        const milkshake_price   = milkshake_pool.algo_reserve / milkshake_pool.milkshake_circulation_supply;
        const to_milkshake      = claimed_algos / milkshake_price;            
        const res             = algosdk.microalgosToAlgos(to_milkshake);
        
        return res.toLocaleString(navigator.language);
    };

        
    return (
        <>

        <div class="white-block" id="claim-rewards">
            <div class="title">Claim rewards</div>
            
            <table class="table-structure">
            <tr>
                <td class="frozen">Frozen: </td>
                <td class="td-amount frozen"><AlgoAmount value={props.frozen_rewards} icon-width="1em" /></td>
            </tr>
            <tr>
                <td>Claimable:</td>
                <td class="td-amount"><AlgoAmount value={props.claimable_rewards} icon-width="1em" /></td>
            </tr>
            <tr>
                <td id="received-amount-title">You will receive:</td>
                <td class="td-amount">
                    {
                        rewards_as_milkshake ? 
                        <div id="rewards-as-milkshake">
                            <div class="milkshake-token-icon"></div><div>{formatMilkshakeRewards(claim_amount, props.milkshake_pool)}</div>
                        </div>
                        :
                        <AlgoAmount value={claim_amount} icon-width="1em" />
                    }
                    <div id="rewards-milkshake">
                        <input type="checkbox" onChange={(e)=>setRewardsAsMilkshake(e.target.checked)}/>Receive rewards in $MLKSHAKE
                    </div>
                </td>
                </tr>           
            </table>
            
            <Slider 
                onChange = {(value) => setClaimAmount(value)} 
                max      = {props.claimable_rewards} 
            />
            <div class="flex" id="buttons-panel">
                <button class="small-btn" onClick={(e) => props.onClaim(claim_amount, rewards_as_milkshake)}>Proceed</button>
                <button class="small-btn" onClick={props.onClose}>Cancel</button>
            </div>
        </div>    
                    
       
        </>
    );
}

