import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { Link } from 'react-router-dom';        
import { useLocation } from 'react-router-dom';

export default (props) => {

    const [pathname, setPathname]   = useState("/");
    const location                  = useLocation();
    
    useEffect(() => {
        
        const pathname = location.pathname.split("/")[1];//window.location.pathname.split("/")[1];
      
        setPathname(pathname);
        
    });
    
    /**/
    return (
        <>
        <ul id="menu">
            <li>
                <Link 
                    to=     "/"                
                    class=  {pathname === "" ? "a-selected" : ""}>
                    Stake & rewards
                </Link>
            </li><li>
                <Link 
                    to=     "/address-stats"   
                    class=  {pathname === "address-stats" ? "a-selected" : ""}>
                    Your stats
                </Link>
            </li>
            <li>
                <Link 
                    to=     "/activity"        
                    class=  {pathname === "activity" ? "a-selected" : ""}>
                    Pool activity
                </Link>
            </li>
            <li>
                <Link 
                    to= "/activity">
                    How this works
                </Link>
            </li>            
        </ul>
        

        </>
    );
}
