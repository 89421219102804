// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#milkshake-pool{
    
    #container{
        
        display: flex;
        font-size: 0.8em;
        gap: 5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/milkshake-pool/style.css"],"names":[],"mappings":"AAAA;;IAEI;;QAEI,aAAa;QACb,gBAAgB;QAChB,QAAQ;IACZ;AACJ","sourcesContent":["#milkshake-pool{\r\n    \r\n    #container{\r\n        \r\n        display: flex;\r\n        font-size: 0.8em;\r\n        gap: 5px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
