import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { Link } from 'react-router-dom';        
import { useLocation } from 'react-router-dom';
import Led from "../../elements/led/led";

export default (props) => {

   
    const draw = (last_round_onchain, last_processed_round) => {
                
        const delay = parseInt(last_round_onchain) - parseInt(last_processed_round);
        
        return delay > 5 ? 
        <div id="container"><Led status="off" /><div id="msg">The pool is unsync of {delay} blocks</div></div>
        : 
        <div id="container"><Led status="on" /><div id="msg">The pool is sync</div></div> 
           
    };
    
    
    return (
        <div id="rounds-sync">        
            {draw(props["last-round-onchain"], props["last-processed-round"])}
        </div>
    );
}
