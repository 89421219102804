import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import Toggle                                   from "../../elements/toggle/toggle";
import Loader                                   from "../../elements/loaders/layers";
import Helpers                                  from "../../includes/helpers"
import Algostack                                from "../../includes/algostack"
import AddressStats                             from "./address-stats";
import algosdk, {Transaction}                   from "algosdk";

        
 
export default (props) => {
        
    const [loading,                 setLoading]                 = useState(false); 
    const [user,                    setUser]                    = useState({});
    const [unfreeze_rewards,        setUnfreezeRewards]         = useState(null);
    const [view,                    setView]                    = useState("stakes_unstakes");
    const [activity,                setActivity]                = useState([]); 
       
        
    useEffect(() => {
        
        loadUnfreezeRewardsByPeriod();
        
    }, []);
    
    
    useEffect(() => {        
        
        if(props.user){
                
            loadActivity(view, props.user);              
        }
        
    }, [props.user]);


    // Load user's stake from chain
    const loadActivity = async (view, user) => {               

        const get = await Api.get("address-activity", {address:user.address, view:view});

        setLoading(false);

        setActivity(get.content.length > 0 ? get.content : null);        
    };
   
    
    const loadUnfreezeRewardsByPeriod = async () => {
        
        const get = await Api.get("rewards/unfreeze-in-24h");
        
        setUnfreezeRewards(get.content);
    }; 


    const loadView = () => {
        
        const params   = new URLSearchParams(window.location.search)
        const get_view = params.get("view") ? params.get("view").split(",") : null;
        
        if(get_view){
            
            setView(get_view);           
        }
    }
         
        
    const getState = (view, mode) => {
        
        const res = view.includes(mode) ? "on" : "off"                
        
        return res;
    };
    
    
    const onChangeView = async (e, view, user) => {
        
        setActivity(null);
        setLoading(true);
        
        e.preventDefault();
        
        setView(view);
        loadActivity(view, user.address)
    };        
      
    
    const firstLetterUpperCase = (str) => {
        
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    
    
    const formatTxnId = (txn_id) => {
        
        return txn_id.substring(0, 10);
    };
    
    
    const drawEntry = (entry) => {
        
        const res = [];
        
        if(entry.type === "action"){
            
            res.push(drawActionEntry(entry));
        }        
        else if(entry.type === "earn"){
            
            res.push(drawEarnEntry(entry));
        }
        else if(entry.type === "claim"){
            
            res.push(drawClaimEntry(entry));
        }
        
        return res;
    };
   
    
    const drawActionEntry = (entry) => {
        
        return  <tr>
                    <td class="type">
                        {firstLetterUpperCase(entry.action)}
                        <div class="action-amount"><AlgoAmount icon-width="13px" value={entry.amount} /></div>
                    </td>
                    <td>
                        <div class="details-main">                            
                            <div class="date">{Helpers.formatDate(entry.ts)}</div>
                        </div>
                        <div class="details-sub">
                            <div>Block: {entry.block}</div>
                            <div>Transaction: {formatTxnId(entry.tx_id)} ...</div>
                        </div>
                    </td>
                </tr>
    };
    
    
    const drawEarnEntry = (entry) => {
        
        const rewards                   = entry.rewards.split(".");
        const rewards_in_microalgos     = rewards[0];
        const rewards_extra_decimals    = rewards[1];
        const is_frozen                 = entry.unfreeze_ts > Date.now();
        
        return  <div class="earn-entry">
                                         
                    <div class="date">{Helpers.formatDate(entry.ts)}</div>                            
                    
                    <div class={is_frozen ? "type frozen" : "type"}>                        
                        <div class="action-amount"><AlgoAmount icon-width="15px" value={rewards_in_microalgos} /></div>
                    </div>
                    <div class="frozen frozen-until">Frozen until {Helpers.formatDate(entry.unfreeze_ts)}</div>
                    <table>
                        <tr>
                            <td>Block</td>
                            <td>{entry?.block_data.block}</td>
                        </tr>
                        <tr>
                            <td>Block rewards</td>
                            <td><AlgoAmount icon-width="15px" value={entry?.block_data.rewards} /></td>
                        </tr>
                       
                    </table>                                                    
                </div>
    };
    
    
    const drawClaimEntry = (entry) => {
        
        return  <tr>
                    <td class="type">
                        Claimed rewards
                        <div class="action-amount"><AlgoAmount icon-width="13px" value={entry.amount} /></div>
                    </td>
                    <td>
                        <div class="details-main">                            
                            <div class="date">{Helpers.formatDate(entry.ts)}</div>
                        </div>
                        <div class="details-sub">
                            <div>Block: {entry.block}</div>
                            <div>Transaction: {formatTxnId(entry.tx_id)} ...</div>
                        </div>
                    </td>
                </tr>
    };
    
    
    return (
        <div id="address-activity">
            <div id="user-stats">
                {props.user ? <AddressStats user={props.user} unfreeze-rewards={unfreeze_rewards}/> : <></>}
            </div>
            <div class="flex" id="menu">
                <a href="#" class={view === "stakes_unstakes" ? "a-selected" : ""} onClick={(e) => onChangeView(e, "stakes_unstakes", props.user)}>Stakes & unstakes</a>                       
                <a href="#" class={view === "received_rewards" ? "a-selected" : ""} onClick={(e) => onChangeView(e, "received_rewards", props.user)}>Received rewards</a>
                <a href="#" class={view === "claimed_rewards" ? "a-selected" : ""} onClick={(e) => onChangeView(e, "claimed_rewards", props.user)}>Claimed rewards</a>
            </div>
            {
                !activity && !loading ?
                    <>
                    <div id="no-data">
                        No data to show yet
                    </div>                    
                    </>
                :
                <></>
            }
            {
                activity && activity.length > 0 ?
                    <>
                    <div class="white-block" id="details">
                        <div>                 
                        {
                            activity.map(entry => {

                                return drawEntry(entry)
                            })
                        }            
                        </div> 
                    </div>                    
                    </>
                :
                <></>
            }
            {loading ? <div id="loader"><Loader /></div> : ""}
        </div>
    );
}
