import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import algosdk                                  from "algosdk";
 
export default (props) => {
      
      
    const format = (value) => {
        
        try{
            value = algosdk.microalgosToAlgos(parseInt(props.value));
        }
        catch(e){
            
            value = 0;
        }
    
        value = props.format && props.format === "int" ? parseInt(value) : value;
     
        return value.toLocaleString(navigator.language);
    };
   
    return (
            
        <div class="flex algo-amount">
            {                 
            <><AlgorandSymbol class="algorand-icon" style={props["icon-width"] ? {width:props["icon-width"]} : {}}  />       
                <div>
                    {format(props.value)}                           
                </div>                        
            </>
           }
        </div>
    );
}

