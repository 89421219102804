// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#doc{
    
    max-width:600px;
    margin:auto;
        
    section{
        
        margin-bottom:50px;
        
        h2{
        
            font-size:1.3em
        }
    }
    
    
}`, "",{"version":3,"sources":["webpack://./src/components/doc/style.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,WAAW;;IAEX;;QAEI,kBAAkB;;QAElB;;YAEI;QACJ;IACJ;;;AAGJ","sourcesContent":["#doc{\r\n    \r\n    max-width:600px;\r\n    margin:auto;\r\n        \r\n    section{\r\n        \r\n        margin-bottom:50px;\r\n        \r\n        h2{\r\n        \r\n            font-size:1.3em\r\n        }\r\n    }\r\n    \r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
