// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../icons/cursor-rewards.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#claim-rewards{
   
    td{
        padding-bottom:10px;
    }
    
    .td-amount{
        
        padding-left:20px;
    }
    
    .frozen{
        
        color:var(--frozen);
    }
    
    .slider {
        
        background: var(--color-b);
    }
    
    button{
    
        border-color: var(--color-b);   
        color: var(--color-b);
    }
    
    button:hover{
        
        background-color:var(--color-b);
        color:white;
    }
    
    .slider::-webkit-slider-thumb {
    
        background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});     
    }

    .slider::-moz-range-thumb {
  
        background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});        
    }
    
    #received-amount-title{
        
        align-content: flex-start;
    }
    
    #rewards-milkshake{
        
        display:flex;
        gap:10px;
        margin-top:10px;
        
        input{
            
            margin:0
        }
    }
    
    #rewards-as-milkshake{
    
        display: flex;
        color: var(--color-a);
        gap: 5px;    
        height:20px;
        align-items: baseline;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/user-rewards/style.css"],"names":[],"mappings":";AACA;;IAEI;QACI,mBAAmB;IACvB;;IAEA;;QAEI,iBAAiB;IACrB;;IAEA;;QAEI,mBAAmB;IACvB;;IAEA;;QAEI,0BAA0B;IAC9B;;IAEA;;QAEI,4BAA4B;QAC5B,qBAAqB;IACzB;;IAEA;;QAEI,+BAA+B;QAC/B,WAAW;IACf;;IAEA;;QAEI,mDAAiD;IACrD;;IAEA;;QAEI,mDAAiD;IACrD;;IAEA;;QAEI,yBAAyB;IAC7B;;IAEA;;QAEI,YAAY;QACZ,QAAQ;QACR,eAAe;;QAEf;;YAEI;QACJ;IACJ;;IAEA;;QAEI,aAAa;QACb,qBAAqB;QACrB,QAAQ;QACR,WAAW;QACX,qBAAqB;IACzB;;AAEJ","sourcesContent":["\r\n#claim-rewards{\r\n   \r\n    td{\r\n        padding-bottom:10px;\r\n    }\r\n    \r\n    .td-amount{\r\n        \r\n        padding-left:20px;\r\n    }\r\n    \r\n    .frozen{\r\n        \r\n        color:var(--frozen);\r\n    }\r\n    \r\n    .slider {\r\n        \r\n        background: var(--color-b);\r\n    }\r\n    \r\n    button{\r\n    \r\n        border-color: var(--color-b);   \r\n        color: var(--color-b);\r\n    }\r\n    \r\n    button:hover{\r\n        \r\n        background-color:var(--color-b);\r\n        color:white;\r\n    }\r\n    \r\n    .slider::-webkit-slider-thumb {\r\n    \r\n        background: url('../../icons/cursor-rewards.png');     \r\n    }\r\n\r\n    .slider::-moz-range-thumb {\r\n  \r\n        background: url('../../icons/cursor-rewards.png');        \r\n    }\r\n    \r\n    #received-amount-title{\r\n        \r\n        align-content: flex-start;\r\n    }\r\n    \r\n    #rewards-milkshake{\r\n        \r\n        display:flex;\r\n        gap:10px;\r\n        margin-top:10px;\r\n        \r\n        input{\r\n            \r\n            margin:0\r\n        }\r\n    }\r\n    \r\n    #rewards-as-milkshake{\r\n    \r\n        display: flex;\r\n        color: var(--color-a);\r\n        gap: 5px;    \r\n        height:20px;\r\n        align-items: baseline;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
