import './style.css';

export default (props) => {


    const getStyle = (props) => {
               
        let style = {};

        if(props.tiny){
            
            style.fontSize = "3px";
            style.borderTopWidth = "1px";
            style.borderRightWidth = "2px";
            style.borderBottomWidth = "3px";
        }
        
        if(props.color){
                       
            style.borderTopColor = props.color;
            style.borderRightColor = props.color;
            style.borderBottomColor = props.color;
        }
       
        return style;
    };
    
    
    return (
            
       <div class="loader" style={getStyle(props)}></div>
    );
}

