import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import Toggle                                   from "../../elements/toggle/toggle";
import Loader                                   from "../../elements/loaders/layers";
import Socket                                   from "../../includes/ws";
import Contracts                                from "../../includes/contracts";
import FadeIn                                   from 'react-fade-in';
import algosdk, {Transaction}                   from "algosdk";
        
 
export default (props) => {
        
    const [loading, setLoading]                         = useState(false); 
    const [last_processed_block,    setLastProcessedBlock] = useState(null);
    const [last_round,              setLastRound] = useState(null);
    const [activity, setActivity]                       = useState([]); 
    const [waiting, setWaiting]                         = useState(true);
    
    
    useEffect(() => {
        
        
        listenWs();
        
    }, []);
    
    
    const listenWs = async () => {      
       
        Socket.listen().onAny((event, ...args) => {
     
            if(event === "block"){
                
                setLastProcessedBlock(args[0].round); 
            }
            
            setWaiting(false);
            
            activity.unshift({event:event, args:args[0]});

            setActivity([...activity.slice(0,100)]);

        });                               
    };           
    
    
    const drawEntry = (index, event_type, args) => {
        
        let res = null;
        
        if(event_type === "block"){

            res = drawBlockEntry(index, args); 
               
        }    
        else if(event_type === "pool"){
                
            res = drawPoolEntry(args);
        }
        else if(event_type === "stake"){
                 
            res = drawStakeEntry(args);
        }
        
        return res;
    };
    
    
    const drawBlockEntry = (index, args) => {        
        
        let is_from_pool = <div class="is-not-from-pool">The proposer is not a Milkshake pool</div>;
        let global_stake = null;
        let is_valid_pool = false;
        
        if(args.is_from_pool === "rewards"){
            
            is_from_pool = <div class="success">The proposer is a reward pool</div>    
            global_stake = "Rewards pool";
            is_valid_pool = true;
        }
        else if(args.is_from_pool === true){
            
            is_from_pool = <div class="success">The proposer is a staking pool</div>
            global_stake = "Staking pool";
            is_valid_pool = true;
        }                
        
        return  <>                 
                    <div class="flex"><div>Block #{args.round}</div><div>{is_from_pool}</div></div>
                    <div>Proposer: {args.proposer}</div>
                    <div class="flex rewards"><div>Rewards:</div><div><AlgoAmount value={args.rewards} icon-width="12px" /></div></div>  
                    { 
                        is_valid_pool ?
                        <>
                        <div class="flex rewards"><div>Distributed:</div><div>{args.distributed} microalgos</div></div>
                        <div class="flex rewards"><div>{global_stake}:</div><div>{args.app_stake}</div></div>
                        </>
                        : ""
                    }
                </>                
    };
    
    
    const drawPoolEntry = (args) => {        

        return  <div class="flex rewards">{args}</div>                                                   
    };
    
    const drawStakeEntry = (args) => {        
       
        return  <div class="flex rewards">
                    <div>{args.address.substring(0,12)} ... staked</div>
                    <div><AlgoAmount value={args.amount} icon-width="12px" /> </div>
                    <div>to pool {args.pool}</div>                                                   
                </div>
    };
    
    return (
        <div id="live">
            
            <div id="console">
            <div id="sync">
                <div>Last block from chain: {last_round}</div>
                <div>Last processed block: {last_processed_block}</div>
                { 
                    last_round - last_processed_block > 5 ? 
                    <div class="error">Process is {last_round - last_processed_block} blocks delayed, currently synchronizing ...</div>
                    :
                    <div class="success">is sync</div>
                }
            </div>
            {
                waiting ? <div>Waiting next block ...</div> : ""
            }
            {
                activity.map((entry, index) => {

                   return   <div class="entry">
                            {drawEntry(index, entry.event, entry.args)}
                            </div>
                })
            }
            {loading ? <Loader /> : ""}
            </div>
        </div>
    );
}
