import './style.css';
import React, { useState, useEffect, useRef }    from 'react';
import Api      from "../../includes/api"        
import Helpers  from "../../includes/helpers"
import Socket   from "../../includes/ws"; 
 
 
export default (props) => {
        
    const [summary, setSummary] = useState([]);
    const summary_ref           =  useRef(summary);
    
    
    useEffect(() => {
       
       loadSummary();
       listenWs();
       
    }, []);
  
  
    useEffect(() => {
        
        summary_ref.current = summary;
        
    }, [summary]);
  
  
    const listenWs = async () => {
                     
        Socket.listen().onAny((event, ...args) => {
     
            if(summary_ref.current.length > 0){
                
                const last_entry_id = summary_ref.current[0].id;
                
                loadSummary(last_entry_id);
            }
            /*
            activity.unshift({event:event, args:args[0]});

            setActivity([...activity.slice(0,100)]);
            */
        });                               
    };     
    
    
    const loadSummary = async (from_id = null) => {
        
        const get = await Api.get("activity-summary", {from_id:from_id});
   
        setSummary(get.content);                
    };
    
    
    const reloadSummary = async() => {
        
        console.log(summary);
    };
    
    const drawEntry = (entry) => {
        
        let res = [];
        
        res.push(<div class="message">
                    <div class="date">{Helpers.formatDate(entry.ts)}</div>
                    <div class="content">{entry.message}</div>
                </div>);
        
        return res;
    };


    
    
    const drawStakeAndUnstake = (entry) => {
        
        return <div>{entry.ts}</div>;
    };
            
            
    return (
        <div id="summary" class="white-block">
        {
            summary.map(entry => {
                
                return drawEntry(entry);
            })
        }
        </div>
    );
}
