import './App.css';
import React, { useState, useEffect,useRef }    from 'react';
import BlocksViewer     from "./components/blocks-viewer/blocks-viewer";
import TopBar           from "./components/top-bar/top-bar";
import Home             from "./components/home/home";
import Admin            from "./components/admin/admin";
import Doc              from "./components/doc/doc";
import AddressActivity  from "./components/address-activity/address-activity";
import Activity         from "./components/activity/activity";
import Menu             from "./components/menu/menu";
import Api              from "./includes/api"
import Contracts        from "./includes/contracts"
import Socket           from "./includes/ws";


import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() { 

    const [user,                setUser]                = useState(null);
    const [pools_status,        setPoolsStatus]         = useState(null);
    const [last_round_onchain,  setLastRoundOnChain]    = useState(null);
    const [last_process_round,  setLastProcessedRound]   = useState(null);
    const [nodes,               setNodes]              = useState([]);
    const [milkshake_pool,     setMilkshakePool]      = useState({});
    
    useEffect(() => {
             
        loadUser();
        loadPoolsStatus();
        listenWs();
        getLastRoundOnChain();
        getNodes();
        getMilkshakePool();
        
    }, []);
    
    
    const getNodes = async () => {
        
        const nodes = await Api.get("nodes");
            
        setNodes(nodes.content);
            
        setTimeout(getNodes, 30000);
    };
    
    
    const getMilkshakePool = async () => {
        
        const get = await Contracts.getMilkshakePool();

        setMilkshakePool(get);  
            
        setTimeout(getMilkshakePool, 30000);
    };
    
    const listenWs = async () => {
        
         Socket.listen().onAny((event, ...args) => {

            if(event === "block"){
                
                loadUser();
                setLastProcessedRound(args[0].round);                                
            }
        });
    /*
        Socket.listen().onAny((event, ...args) => {
     
            console.log(event, args);
            if(user && event === user.address){

               loadUser();
            }   
        });  
         
     */                             
    };
    
    
    // Load user's stake from chain
    const getLastRoundOnChain = async () => {               
       
        setInterval(async()=>{
        
            const last_round = await Contracts.getLastRound();

            setLastRoundOnChain(last_round);  
            
        }, 3000);
    };
    
        
    
    const loadUser = async () => {
        
        const user  = await Api.get("user");
      
        if(user.content?.address){
               
            setUser(user.content);
        }
        else{
            
            setUser(false);
        }
    };
    
    
    const loadPoolsStatus = async () => {
        
        const get = await Api.get("pools/status");
        
        setPoolsStatus(get.content);
    };
    
    
    const onDisconnect = () => {
        
        setUser(false);
    };
    
    return (
        <>
        <BrowserRouter>   
        <header>    
            <TopBar 
                user                    = {user} 
                onDisconnect            = {onDisconnect} 
                last-round-onchain      = {last_round_onchain} 
                last-processed-round    = {last_process_round}
                nodes                   = {nodes}
                milkshake-pool          = {milkshake_pool}
            />
            
            <div id="logo"></div> 
            <Menu />
        </header>
        <main>
             
               <Routes>
                   <Route path="/"              element={<Home user={user} pools-status={pools_status} milkshake-pool={milkshake_pool} onConnect={loadUser}/>} />            
                   <Route path="/pools"         element={<Admin />} />
                   <Route path="/address-stats" element={<AddressActivity user={user}/>} />
                   <Route path="/activity"      element={<Activity page="summary"/>} />
                   <Route path="/activity/live" element={<Activity page="live" last-round-onchain = {last_round_onchain} last-processed-round = {last_process_round} />} />
                   <Route path="/doc"           element={<Doc />} />
               </Routes>         
                        
        </main> 
        </BrowserRouter>
        <BlocksViewer />
        
        </>
    );

}

export default App;