import './style.css';
 
export default (props) => {
   
    return (
            
       <div id="doc">
        
        <section>
            <h2>1. Core Concept</h2>
            <p><em>Milkshake.fi</em> is an Algorand consensus participation pool where users can stake their ALGOs into a smart contract. The staked funds are used to participate in Algorand's block consensus mechanism, ensuring decentralization and security of the network while generating rewards for participants.</p>
        </section>

        <section>
            <h2>2. Rewards Distribution and Compounding</h2>
            <ul>
                <li>Rewards earned from block consensus are distributed proportionally to users based on their stake.</li>
                <li>These rewards are locked in a separate "rewards pool" for 30 days, after which they become accessible.</li>
                <li>During this 30-day lock period, the rewards pool itself participates in Algorand's consensus mechanism, generating additional rewards. This creates a compounding effect that increases the overall yield for participants.</li>
            </ul>
        </section>

        <section>
            <h2>3. Key Differentiator</h2>
            <p>The service combines the simplicity of staking with enhanced rewards from a compounding mechanism, making it an attractive option for ALGOs holders looking to maximize their returns. By reinvesting rewards and leveraging the rewards pool's participation in consensus, users benefit from exponential growth opportunities.</p>
        </section>

        <section>
            <h2>4. Token ($Milkshake)</h2>
            <p>The <em>Milkshake</em> token is a unique reward option available to users who choose to convert their earned ALGOs into $Milkshake rather than receiving them as ALGOs. Here’s how it works:</p>

            <ul>
                <li><strong>Earning $Milkshake:</strong>
                    <ul>
                        <li>After the 30-day lock period, users can opt to convert their earned rewards into $Milkshake tokens instead of withdrawing them as ALGOs.</li>
                        <li>The ALGOs that would have been distributed to the user are sent into a $Milkshake/ALGO liquidity pool instead.</li>
                    </ul>
                </li>
                <li><strong>The $Milkshake/ALGO Pool:</strong>
                    <ul>
                        <li>The pool is not an automated market maker (AMM) but a fixed pool, allowing users to exchange $Milkshake for ALGOs at any time.</li>
                    </ul>
                </li>
                <li><strong>Burn Mechanism:</strong>
                    <ul>
                        <li>When a user decides to exchange their $Milkshake tokens for ALGOs, the user receives ALGOs from the pool.</li>
                        <li>The $Milkshake tokens exchanged are permanently removed from the supply (burned), reducing the total circulating $Milkshake.</li>
                    </ul>
                </li>
                <li><strong>Impact of Burning on Value:</strong>
                    <ul>
                        <li>Burning reduces the supply of $Milkshake, making the remaining tokens scarcer. As demand remains constant or grows, this scarcity increases the value of each $Milkshake token.</li>
                        <li>Since the ALGOs in the pool remain constant or increase (via periodic injections), burning tokens effectively increases the proportion of ALGOs backing each remaining $Milkshake token.</li>
                    </ul>
                </li>
                <li><strong>Fee Injection:</strong>
                    <ul>
                        <li>To further enhance the value of $Milkshake, 10% of the fees generated by the service are injected into the $Milkshake/ALGO pool.</li>
                        <li>This regular injection increases the pool's ALGO reserves, raising the value of $Milkshake over time and providing additional incentives for participation.</li>
                    </ul>
                </li>
            </ul>
        </section>

        <section>
            <h2>5. Growth Mechanism</h2>
            <p>By combining staking rewards, compounding through the rewards pool, and a sustainable token model with built-in scarcity and value injection mechanisms, <em>Milkshake.fi</em> provides an innovative and rewarding platform for ALGOs holders. The periodic injection of ALGOs and the deflationary nature of $Milkshake create long-term growth potential and incentivize active participation in the ecosystem.</p>
        </section>
    </div>
    );
}