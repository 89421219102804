import './style.css';

import BlocksViewer                     from "../../components/blocks-viewer/blocks-viewer";
import Stats                            from "../../components/stats/stats";
import Splash                           from "../../components/splash/splash";
import Earnings                         from "../../components/user-earnings/user-earnings";
import Pools                            from "../../components/pools/pools";
import Metrics                          from "../../components/metrics/metrics";
import ConnectWallet                    from "../../components/connect-wallet/connect-wallet";
import TopBar                           from "../../components/top-bar/top-bar";
import Loader                           from "../../elements/loaders/layers";
import Api                              from "../../includes/api"
import Socket                           from "../../includes/ws";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FadeIn                           from 'react-fade-in';
import {useState, useEffect}            from 'react';


export default (props) => {
 
    const [show_splash, setShowSplash]      = useState(false);      
    const [app_stake,   setAppStake]        = useState(null);
    const [app_rewards, setAppRewards]      = useState(null);
    const [pools_status, setPoolsStatus]    = useState(null);

    
    const drawPools = (pools_status) => {
        
        const res = [];
        
        if(pools_status){
            
            res.push(
            <FadeIn>
                <Pools 
                app_stake                   = {props?.["pools-status"]?.staking_pools?.stake} 
                app_stake_proposed_blocks   = {props?.["pools-status"]?.staking_pools?.proposed_blocks?.count}
                app_stake_earned_rewards    = {props?.["pools-status"]?.staking_pools?.proposed_blocks?.earned_rewards}
                app_rewards                 = {props?.["pools-status"]?.rewards_pools?.stake} 
                app_rewards_proposed_blocks = {props?.["pools-status"]?.rewards_pools?.proposed_blocks?.count}
                app_rewards_earned_rewards  = {props?.["pools-status"]?.rewards_pools?.proposed_blocks?.earned_rewards}
                frozen_rewards              = {props?.["pools-status"]?.rewards_pools?.frozen_rewards}                        
                />
            </FadeIn>);
        } 
        else{
            
            res.push(<Loader />);
        }
        
        return res;
    }
    
    
    return (
        <div id="home">   

        {   
            props.user ? 
                <>
                <div id="layout-earnings">
                    <Earnings 
                    app_stake       = {props?.["pools-status"]?.staking_pools?.stake} 
                    app_rewards     = {props?.["pools-status"]?.rewards_pools?.stake} 
                    user            = {props.user} 
                    milkshake-pool  = {props?.["milkshake-pool"]}
                    />
                    <div id="pools-block">{drawPools(props["pools-status"])}</div> 
                </div>                   
                </>
                : ""
        }
        {
            props.user === false  ?
                <>
                <div id="layout-connect-wallet"><ConnectWallet onConnect={props.onConnect}/></div>
                <div id="layout-earnings">{drawPools(props["pools-status"])}</div>
                </>
                : ""
        }            
                                        
        {show_splash ? <Splash onClose={() => setShowSplash(false)}/> : "" }                
        </div>
    );

}

