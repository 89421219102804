// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#activity{

    #menu{
        
        width: 650px;
        margin: auto;
        display: flex;
        gap: 30px;
        justify-content: left;
        margin-bottom:20px;
        font-size:0.9em;
        
        .selected{
            
            color: var(--color-b);
        }
    }
}

#live{
    
    width: 650px;
    margin: auto;   
    
    
    #console{
        
        background: rgb(24, 24, 24);
        font-size: 0.85em;
        padding:20px 20px 0px 20px;
        
        #sync{
            
            margin-bottom:20px;
        }                
        
        .entry{
        
            color: #bdbdbd;
            padding-bottom:20px;
        }

        .is-not-from-pool{

            color:#4e4e4e;
        }

        .rewards{

            gap:2px;
            flex-flow: wrap;
        }
    }
}


#summary{
    
    width: 650px;
    margin: auto;
    
    .message{
        
        padding-bottom:10px;
        
        .content{
            
            font-size:0.85em;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/activity/style.css"],"names":[],"mappings":"AAAA;;IAEI;;QAEI,YAAY;QACZ,YAAY;QACZ,aAAa;QACb,SAAS;QACT,qBAAqB;QACrB,kBAAkB;QAClB,eAAe;;QAEf;;YAEI,qBAAqB;QACzB;IACJ;AACJ;;AAEA;;IAEI,YAAY;IACZ,YAAY;;;IAGZ;;QAEI,2BAA2B;QAC3B,iBAAiB;QACjB,0BAA0B;;QAE1B;;YAEI,kBAAkB;QACtB;;QAEA;;YAEI,cAAc;YACd,mBAAmB;QACvB;;QAEA;;YAEI,aAAa;QACjB;;QAEA;;YAEI,OAAO;YACP,eAAe;QACnB;IACJ;AACJ;;;AAGA;;IAEI,YAAY;IACZ,YAAY;;IAEZ;;QAEI,mBAAmB;;QAEnB;;YAEI,gBAAgB;QACpB;IACJ;AACJ","sourcesContent":["#activity{\r\n\r\n    #menu{\r\n        \r\n        width: 650px;\r\n        margin: auto;\r\n        display: flex;\r\n        gap: 30px;\r\n        justify-content: left;\r\n        margin-bottom:20px;\r\n        font-size:0.9em;\r\n        \r\n        .selected{\r\n            \r\n            color: var(--color-b);\r\n        }\r\n    }\r\n}\r\n\r\n#live{\r\n    \r\n    width: 650px;\r\n    margin: auto;   \r\n    \r\n    \r\n    #console{\r\n        \r\n        background: rgb(24, 24, 24);\r\n        font-size: 0.85em;\r\n        padding:20px 20px 0px 20px;\r\n        \r\n        #sync{\r\n            \r\n            margin-bottom:20px;\r\n        }                \r\n        \r\n        .entry{\r\n        \r\n            color: #bdbdbd;\r\n            padding-bottom:20px;\r\n        }\r\n\r\n        .is-not-from-pool{\r\n\r\n            color:#4e4e4e;\r\n        }\r\n\r\n        .rewards{\r\n\r\n            gap:2px;\r\n            flex-flow: wrap;\r\n        }\r\n    }\r\n}\r\n\r\n\r\n#summary{\r\n    \r\n    width: 650px;\r\n    margin: auto;\r\n    \r\n    .message{\r\n        \r\n        padding-bottom:10px;\r\n        \r\n        .content{\r\n            \r\n            font-size:0.85em;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
