// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*** layouts ***/

#home{

    #layout-earnings{

        max-width:520px;

        margin:auto;
    }

    #pools-block{

        margin-top:100px;
    }

    .loader{
        
        margin:auto;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/style.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;;IAEI;;QAEI,eAAe;;QAEf,WAAW;IACf;;IAEA;;QAEI,gBAAgB;IACpB;;IAEA;;QAEI,WAAW;IACf;AACJ","sourcesContent":["/*** layouts ***/\n\n#home{\n\n    #layout-earnings{\n\n        max-width:520px;\n\n        margin:auto;\n    }\n\n    #pools-block{\n\n        margin-top:100px;\n    }\n\n    .loader{\n        \n        margin:auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
