// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nodes-sync{
    
    #container{
        
        display:flex;
        align-items: center;
    }
    
    #msg{
        
        font-size:0.8em;
    }        
}
`, "",{"version":3,"sources":["webpack://./src/components/nodes-sync/style.css"],"names":[],"mappings":"AAAA;;IAEI;;QAEI,YAAY;QACZ,mBAAmB;IACvB;;IAEA;;QAEI,eAAe;IACnB;AACJ","sourcesContent":["#nodes-sync{\r\n    \r\n    #container{\r\n        \r\n        display:flex;\r\n        align-items: center;\r\n    }\r\n    \r\n    #msg{\r\n        \r\n        font-size:0.8em;\r\n    }        \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
