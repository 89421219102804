import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { Link } from 'react-router-dom';        
import { useLocation } from 'react-router-dom';
import AlgoAmount from "../../elements/algo-amount/algo-amount";
import algosdk from "algosdk";

export default (props) => {

 
    const draw = (data) => {
              
        const price = data?.algo_reserve && data?.milkshake_circulation_supply ? data.algo_reserve / data.milkshake_circulation_supply : null;

            return price ? <div id="container">$MLKSHAKE = <AlgoAmount value={algosdk.algosToMicroalgos(price)} icon-width="11px"/></div> : "-";
    };
    
    
    return (
        <div id="milkshake-pool">        
            {draw(props.data)}
        </div>
    );
}
